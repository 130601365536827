<template>
  <div class="space-y-4">
    <asom-modal
      title="Edit PSM Manual Record"
      v-model="showModal"
      :dismissible="false"
    >
      <p>
        This will Void PSM Transaction
        {{ this.$route.params.transactionNo || "" }}
      </p>
      <p class="pt-4">Are you sure you would like to proceed?</p>
      <div class="flex flex-row-reverse pt-4">
        <div class="pl-4">
          <asom-button
            @click="onSubmit"
            :disabled="isSubmitting"
            :loading="isSubmitting"
            text="OK"
          />
        </div>
        <div>
          <asom-button
            @click="onCloseModal"
            text="Cancel"
            variant="secondary"
          />
        </div>
      </div>
    </asom-modal>
    <asom-card>
      <asom-alert v-if="error" variant="error" :error-message="error" />
      <h3 class="text-lg font-medium text-gray-900">Transaction Information</h3>
      <div class="grid sm:grid-cols-1 md:grid-cols-2" v-if="transactionData">
        <asom-form-field label="Transaction No">
          <p class="mt-1 font-semibold">{{ transactionData.transactionNo }}</p>
        </asom-form-field>
        <asom-form-field label="Location">
          <p class="mt-1 font-semibold">
            {{ transactionData.account.accountName }}
          </p>
        </asom-form-field>
        <asom-form-field label="PSM" v-if="transactionData.psm">
          <p class="mt-1 font-semibold">{{ transactionData.psm.name }}</p>
        </asom-form-field>
        <div></div>
        <asom-form-field label="Input Type">
          <p class="mt-1 font-semibold">
            {{ inputType(transactionData.inputType) }}
          </p>
        </asom-form-field>
        <asom-form-field v-if="showGTM" label="GTM/TUK">
          <p class="mt-1 font-semibold">
            {{ transactionData.gtm.accountName }}
          </p>
        </asom-form-field>
        <div v-else></div>
        <div>
          <asom-form-field label="Input">
            <p class="mt-1 font-semibold">{{ transactionData.input }}</p>
          </asom-form-field>
          <asom-form-field label="Description">
            <p class="mt-1 font-semibold">{{ transactionData.description }}</p>
          </asom-form-field>
          <asom-form-field
            label="Remarks"
            v-if="get(transactionData, 'remarks', false)"
          >
            <p class="mt-1 font-semibold">{{ transactionData.remarks }}</p>
          </asom-form-field>
        </div>
        <div>
          <asom-form-field label="Waived (-)">
            <p class="mt-1 font-semibold">${{ transactionData.waived }}</p>
          </asom-form-field>
          <asom-form-field label="Payment (-)">
            <p class="mt-1 font-semibold">${{ transactionData.payment }}</p>
          </asom-form-field>
          <asom-form-field label="Receipt (+)">
            <p class="mt-1 font-semibold">${{ transactionData.receipt }}</p>
          </asom-form-field>
        </div>

        <asom-form-field
          v-if="showPassengerSignature"
          label="Passenger Signature"
        >
          <asom-signature-view
            :file-id="get(transactionData, 'passengerSignature.attachmentId')"
          />
        </asom-form-field>

        <asom-form-field
          class="col-span-2"
          v-if="selectedRecordAttachments.length > 0"
          label="Supporting Document"
        >
          <asom-upload-file-list
            :files="selectedRecordAttachments"
            disabled-remove
          />
        </asom-form-field>
      </div>
      <div class="flex justify-end space-x-4">
        <asom-button
          text="Back"
          variant="secondary"
          @click="$router.push({ name: 'PSM Manual Record Main Page' })"
        />
        <asom-button
          v-if="editable"
          text="Void"
          variant="error"
          @click="submitClicked"
          :loading="isSubmitting"
        />
        <asom-button
          v-if="editable"
          text="Edit"
          @click="editClicked"
          :loading="isSubmitting"
        />
      </div>
    </asom-card>
  </div>
</template>

<script>
import get from "lodash.get";
import {
  editPsmLog,
  getPSMDetails,
} from "../../../../services/cashManagement.service";
import { mapGetters } from "vuex";

export default {
  name: "ViewManualRecord",
  data() {
    return {
      transactionData: null,
      isLoading: false,
      isSubmitting: false,
      error: null,
      showModal: false,
    };
  },
  computed: {
    ...mapGetters({
      canEditPSMManualRecord: "auth/canEditPSMManualRecord",
    }),
    inputTypes() {
      return [
        { value: "1", label: "Passenger name", description: "" },
        { value: "2", label: "CAN ID", description: "" },
        { value: "3", label: "Notebox No.", description: "" },
        { value: "4", label: "Coinbox No.", description: "" },
        { value: "5", label: "PSM Top-up", description: "" },
        { value: "6", label: "Others", description: "" },
      ];
    },
    title() {
      return "View PSM Transaction " + get(this.$route.params, "transactionNo");
    },
    selectedRecordAttachments() {
      return get(this.transactionData, "attachments", []);
    },
    showGTM() {
      return [3, 4].includes(this.transactionData.inputType);
    },
    showPassengerSignature() {
      return this.transactionData.inputType === 1;
    },
    editable() {
      return (
        !get(this.transactionData, "addedToShiftEnd", true) &&
        !get(this.transactionData, "addedToCDF", true) && 
        this.canEditPSMManualRecord
      );
    },
  },
  mounted() {
    this.getPSMRecordDetails();
  },
  methods: {
    get,
    inputType(type) {
      if (type == 1) {
        return "Passenger name";
      } else if (type == 2) {
        return "CAN ID";
      } else if (type == 3) {
        return "Notebox No.";
      } else if (type == 4) {
        return "Coinbox No.";
      } else if (type == 5) {
        return "PSM Top-up";
      } else if (type == 6) {
        return "Others";
      } else {
        return "";
      }
    },
    editClicked() {
      this.$router.push({
        name: "Edit Manual Record",
        params: this.$route.params,
      });
    },
    submitClicked() {
      if (this.isSubmitting) return;
      this.showModal = true;
    },
    async getPSMRecordDetails() {
      const result = await getPSMDetails({
        id: get(this.$route.params, "transactionId"),
      });
      if (result.success) {
        this.setTransactionData(get(result.payload, "data"));
      }
    },
    setTransactionData(data) {
      this.transactionData = data;
    },
    async onSubmit() {
      this.error = null;
      this.isSubmitting = true;
      const result = await editPsmLog({
        id: get(this.$route.params, "transactionId"),
        input: this.transactionData.input,
        transactionNo: get(this.transactionData, "transactionNo"),
        inputType: this.transactionData.inputType,
        remarks: "void transaction",
        isVoided: true,
      });
      this.isSubmitting = false;
      this.showModal = false;
      if (result.success) {
        this.$router.push({ name: "PSM Manual Record Main Page" });
      } else {
        this.error = result.payload;
        this.$scrollTop();
      }
    },
    onCloseModal() {
      this.showModal = false;
    },
  },
};
</script>
